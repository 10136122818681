<template>
  <v-skeleton-loader type="chip" height="20" width="80" class="ma-1" color="transparent" />
</template>
<script>
/**
 * TagChipPlaceholder.vue is a placeholder to show the placement any tag while the page is loading.
 *
 * @summary placeholder for tags
 * @author Dominik Haas-Artho
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
 */

export default {
  props: {},
  computed: {},
  methods: {},
};
</script>
