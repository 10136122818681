<template>
  <v-chip class="authorTag text-black"
          :class="{
            'text-white': highlighted,
            smallChip: $vuetify.display.smAndDown,
            authorTagDraggable: draggable,
           }"
          :style="{ height: $vuetify.display.xs ? '15px' : '' }"
          :color="highlighted ? 'primary' : color"
          @click.stop="clicked"
          :draggable="draggable"
          :small="isSmall"
          :density="isSmall ? 'compact' : 'default'"
          :close-icon="mdiClose"
          :closeable="closeable"
          @click:close="$emit('closeClicked', authorName)"
          >
    <v-avatar left class="pr-1">
      <v-icon size="24px" :icon="mdiAccountCircle" />
    </v-avatar>

    {{ authorName }}

    <v-tooltip v-if="authorIsDead"
               location='bottom'>
      <template v-slot:activator="{ props }">
        <v-icon v-bind="props" size='small' :icon="mdiTimerSand" />
      </template>

      {{ authorPassedInfo }}
    </v-tooltip>

  </v-chip>

</template>

<script>
/**
 * TagChipAuthor.vue creates a chip specific for authors with the name as text.
 *
 * @summary tag for authors
 * @author Dominik Haas-Artho
 *
 * Created at     : 2019-10-23 14:11:27
 * Last modified  : 2021-02-10 16:46:06
 *
 * This file is subject to the terms and conditions defined in
 * file 'LICENSE.txt', which is part of this source code package.
*/

import { mdiTimerSand, mdiAccountCircle, mdiClose } from '@mdi/js';

export default {
  props: {
    name: String,
    tooltipText: String,
    highlighted: Boolean,
    colorIcon: {
      type: String,
      default: 'dark',
    },
    color: {
      type: String,
      default: '#f8f8f8',
    },
    asciiDead: String,
    authorPassedInfo: String,
    isSmall: {
      type: Boolean,
      default: false,
    },
    closeable: Boolean,
    draggable: {
      type: Boolean,
      default: undefined,
    },
  },
  data: ()=>({
    mdiTimerSand,
    mdiAccountCircle,
    mdiClose,
  }),
  computed: {
    authorIsDead() {
      return this.asciiDead && this.name ? this.name.includes(this.asciiDead) : false;
    },
    authorName() {
      return this.authorIsDead ? this.name.replace(`(${this.asciiDead})`, '') : this.name;
    },
  },
  methods: {
    clicked: function clicked() {
      this.$emit('clicked', this.name);
    },
  },
};
</script>

<style scoped>

  .authorTag {
    /*
    Remove opacity because with vuetify 3 chip are harder to read
    opacity: 0.85;
    */
    /*
    background-color: #f8f8f8 !important;
    */
    margin: 0 2px !important;
  }

  .authorTag > .v-chip__content > .v-avatar {
    margin-left: -12px !important;
  }

  .authorTag > .v-chip__content > .v-avatar > .v-icon.dark {
    color: rgba(0, 0, 0, 0.87) !important;
  }
  
  .authorTag > .v-chip__content > .v-avatar > .v-icon.white {
    background-color: #00897b !important;
  }
  

  .authorTagDraggable  {
    cursor: move !important;
  }

</style>

<style>

.authorTag .v-chip__close {
  color: red !important;
}

</style>
